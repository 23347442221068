<template>
  <div>
    <ng-list
      class="m-top-base"
      id-key="license_id"
      :reload.sync="reloadData"
      :url-func="$axios.AuthList"
      :params="params"
      :changeData="changeItem"
      :selection="!isAuth"
      :clearSelected="onClearSelected"
      :empty-text="$t('userOrder.tableEmtpy')"
      :changeSelect="onChangeSelect"
    >
      <template v-if="!isAuth" v-slot:header>
        <a-button
          class="bitch-btn"
          type="primary"
          @click="onBitchAuth"
        >批量授权 {{selectedItemIds && selectedItemIds.length ? selectedItemIds.length : ''}}</a-button>
      </template>
      <template v-slot="{item}">
        <div class="good-item pos-relative operation-item">
          <music-detail-item :data="item" @goToActivate="goToActivate"></music-detail-item>
        </div>
      </template>
    </ng-list>

    <dialog-active-auth-package
      :visible.sync="showDialogActive"
      :data="handleData"
      :ids="selectedIds"
      :type="$var.Handle.edit"
      @success="onSuccess"
    ></dialog-active-auth-package>
  </div>
</template>

<script>
  import NgList from '@/components/NgList.vue';
  import MusicDetailItem from '@/pages/components/music/NewPackageDetailItem.vue';
  import DialogActiveAuthPackage from "@/pages/components/order/DialogActiveAuthPackage.vue";
  import {DownloadFile} from '@/utils/function';

  import musicHandle from '@/mixins/musicHandle'

  export default {
    data() {
      return {
        radioActiveValue: this.$val.DidPayOrderType.undoAuth,
        // params: null,
        reloadData: false,
        showDialogActive: false,

        handleData: {},
        selectedIds: [],

        changeItem: [],

        onClearSelected: false,

        selectedItemIds: [],
      }
    },

    props: {
      type: String,
      params: Object,
      isAuth: Boolean
    },

    mixins: [musicHandle],

    components: {
      NgList,
      MusicDetailItem,
      DialogActiveAuthPackage
    },

    methods: {
      onBitchAuth() {
        let list = this.selectedItemIds;

        if (!list || list.length === 0) {
          this.$message.warning('请选择');
          return false;
        }

        let scopesList = this.selectedItemScopes;

        let curInfo = scopesList[0] || {};

        this.handleData = {...curInfo};
        this.selectedIds = this.selectedItemIds;
        this.showDialogActive = true;
      },

      onChangeSelect(selectedScopes, selectedIds) {
        this.selectedItemIds = selectedIds.filter(item => item);
        this.selectedItemScopes = selectedScopes;
      },

      onSuccess() {
        this.reloadData = true;

        this.clearSelected();
      },

      clearSelected() {
        this.onClearSelected = true;

        this.$nextTick(() => {
          this.onClearSelected = false;
        })
      },

      goToActivate(scope) {
        this.handleData = scope;
        this.showDialogActive = true;
      },

      downloadAuthCert(scope) {
        let params = {
          license_id: scope.license_id
        };

        this.$store.commit('changeContentLoading', true);
        this.$axios.DownloadAuth(params).then(res => {
          const data = res.data;

          if (data) {
            try {
              let disposition = res.headers['content-disposition'];
              let filename = '';
              if (disposition) {
                filename = disposition.replace(/.*filename=["']([^"']+)["'].*/, '$1');
              }
              DownloadFile(data, filename);
              this.$message.success(this.$t('result.downloadSuccess'));
            } catch (error) {
              this.$error(error);
            }
          }

          this.$store.commit('changeContentLoading', false);
        }).catch(() => {
          this.$store.commit('changeContentLoading', false);
        })
      },
    }
  }
</script>

<style lang="scss" scoped>
  ::v-deep {
    .ant-list-item{
      padding: 0;
      border: 0;
      padding-top: 10px;
    }
    .item-right {
      .right {
        width: 210px;
      }
    }
  }

  .bitch-btn {
    position: absolute;
    top: 11px;
    right: 0;
    z-index: 100;
  }

  .auth-code {
    margin-top: $space-ver-xs;
  }

  .good-item-right {
    display: flex;
    justify-content: space-between;
  }

  .operation {
    button {
      height: auto;
    }

    .img {
      width: 20px;
      height: 20px;
      margin-top: -5px;
      margin-right: 5px;
    }
  }

  @media screen and (max-width: $screen-width-md) {
    .good-item-right {
      width: 100%;
      flex-direction: column;
      margin-top: $space-ver-sm;

      .price {
        margin-bottom: $space-ver-sm;
      }
    }

    ::v-deep {
      .item-right {
        .right {
          width: 100%;
        }
      }
    }

    .auth-code {
      margin-top: $space-ver-sm;
    }
  }
</style>

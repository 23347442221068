<template>
  <div class="cart-detail-item clearfix">
      <table style="width:100%;table-layout:fixed;">
        <tr>
          <td >
            <a-row class="opera_img">
              <img :src="`${data.logo_url}`" class="log_url_cell" />
              <img src="@/assets/images/web/info_play.svg"
                  v-if="!isPlaying(data.music_id)"
                  @click="onClickIcon(data)"
              />
              <img src="@/assets/images/web/pouse.svg"
                  v-else
                  @click="onPause(data.music_id)"
              /> 
            </a-row>
          </td>
          <td style="width:20%;padding-left:3%;">
            <router-link :to="`/musicDetail/${data.music_id}`" >
              <p style="font-size:16px;font-weight: 600;color: #333333;" class="ellipsis"><b>{{data.music_name}}</b></p>
            </router-link>
            <router-link :to="`/supplierInfo/${data.suplr_id}`">
                <p style="font-size: 14px;font-weight: 400;color: #333333;" class="ellipsis">{{data.suplr_name}}</p>
            </router-link>
          </td>
          <td style="width:50%;">
            <a-row>
              <a-col :xs="24" :sm="24" :md="18" :lg="18" :xl="18">
                <a-col :span="24">
                    {{$t('music.labelUseMethod')}}: {{data.use_mode}}
                  </a-col>
                  <a-col :span="24">
                    {{$t('music.labelUseScene')}}: {{data.auth_scene | toAuthScene}}
                  </a-col>
                  <a-col :span="24">
                    {{$t('music.labelAuthArea')}}: {{data.auth_area | toArea}}
                  </a-col>
                  <a-col :span="24">
                    {{$t('music.labelAuthTime')}}: {{data.auth_time | toAuthPeriod}}
                  </a-col>
                  <a-col v-if="data.is_licensed != $val.CommonCheck.fail && data.code" :span="24">
                    授权代码: {{data.code}}
                  </a-col>
              </a-col>
            </a-row>
          </td>
          <td style="width:20%;">
            <a-button
                v-if="data.is_licensed === $val.CommonCheck.correct"
                class="p-empty m-right-sm lh-1"
                type="link"
                @click="donwloadHighMusic(data, true)"
                >下载无损音乐</a-button>
            <p v-if="data.is_licensed==$val.CommonCheck.fail" style="cursor:pointer;">
                <a-button
                class="p-empty lh-1"
                type="link"
                @click="goToActivate(data)"
                >激活音乐授权</a-button>
            </p>    
            <p v-if="data.is_licensed==1" style="cursor:pointer;">
                <router-link
                :to="`/authCenter?license_id=${data.code}`"
                >查看授权信息</router-link>
            </p>    
          </td>
        </tr>
      </table>
  </div>
</template>

<script>
  import {AuthAreaOptions, BusinessSceneOptions, AuthTimeOptions} from '@/config/options';
  import {toOptionName} from '@/utils/function';
  import MusicPlayIcon from '@/pages/components/music/MusicPlayIcon.vue'
  import musicHandle from '@/mixins/musicHandle'

  export default {
    data() {
      return {

      }
    },
    mixins: [musicHandle],
    props: {
      data: {
        type: Object,
        default() {
          return {}
        }
      },
      canPlay: {
        type: Boolean,
        default: false
      }
    },
    filters: {
      toArea(value) {
        return toOptionName(AuthAreaOptions, value);
      },

      toAuthScene(value) {
        return toOptionName(BusinessSceneOptions, value);
      },

      toAuthPeriod(value) {
        return toOptionName(AuthTimeOptions, value);
      }
    },

    components: {
      MusicPlayIcon
    },
    methods: {
      isPlaying (now_id) {
          let state = this.$store.state
          if (!state.globalVideoInfo) return false
          let { music_id, url } = state.globalVideoInfo
          return (
              state.globalVideoPlaying &&
              music_id &&
              url &&
              now_id === music_id
          )
      },
      onPause () {
          this.$store.commit('changeVideoPlaying', false)
      },
      onClickIcon (item) {
          this.isPlaying(item.music_id) ? this.onPause() : this.onPlay(item)
          this.$store.commit('changeVideoList', this.list || [])
      },
      onPlay (item) {
          let musicInfo = item
          musicInfo.url = musicInfo.audition_url
          this.$store.commit('changeVideoInfo', item)
          this.$nextTick(() => {
              this.$store.commit('changeVideoPlaying', true)
          })
          this.goPlayMusic(musicInfo)
          this.play_music_id = musicInfo.music_id;
      },
      goToActivate(data) {
        this.$emit("goToActivate", data);
      },
    }
  }
</script>

<style lang="scss" scoped>
  tr{
    border: 1px solid #e8e8e8;
  }
  td{
    padding:12px;
  }
  .opera_img{
        text-align:center;
        width: 70px;
        img:not(:nth-child(1)){
            display:none;
            width:20px;
            position: absolute;
            /* margin-top:-55px; */
            margin-top:25px;
            margin-left:-45px;
            cursor:pointer;
        }
        &:hover{
            img:not(:nth-child(1)){
                display:inline-block;
            }
            .log_url_cell{
                opacity: 0.9;
            }
        }
    }
    .log_url_cell{
        width: 100%;
        // height: 90px;
        border-radius: 4px;
        cursor:pointer;
    }
    .logo-info{
        cursor: pointer;
    }
</style>
